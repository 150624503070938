import { defineStore } from 'pinia';

export const useSettingsStore = defineStore('SettingsStore', {
  state: () => ({
    phone: null,
    links: null,
  }),
  actions: {
    async getPhone() {
      const { data: phone } = await useFetch(`/api/v2/settings/site_header_phone`);
      if (phone) this.phone = phone;
    },
    async getLinks() {
      const { data: links } = await useFetch(`/api/v2/settings/site_links`);
      if (links) this.links = links;
    },
  }
});